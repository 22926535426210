import React, { useEffect, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';

function getRelativeTime(date: Date): string {
  return formatDistanceToNow(date, { includeSeconds: true, addSuffix: true });
}

export type TimestampProps = {
  date: Date;
};

export function Timestamp({ date }: TimestampProps): React.JSX.Element {
  const [dateString, setDateString] = useState(() => getRelativeTime(date));

  useEffect(() => {
    const id = setInterval(() => {
      setDateString(getRelativeTime(date));
    }, 5000);
    return () => {
      clearInterval(id);
    };
  }, [date]);

  return <span className="rounded bg-slate-600 px-[0.125rem]">{dateString || 'unknown'}</span>;
}
