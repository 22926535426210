import React from 'react';
import { type PageProps, useStaticQuery, graphql } from 'gatsby';
import Header from './header';
import { Timestamp } from './timestamp';

export default function Layout({ children }: PageProps): React.JSX.Element {
  const gitData = useStaticQuery<Queries.LayoutQuery>(graphql`
    query Layout {
      gitCommit(latest: { eq: true }) {
        hash
        date
      }
    }
  `);

  const hash: string = gitData?.gitCommit?.hash?.slice(0, 7) ?? '';
  const date: Date = new Date(gitData?.gitCommit?.date ?? new Date());

  return (
    <div className="grid h-full min-h-screen w-full grid-rows-[auto_1fr_auto] overflow-hidden bg-slate-900 font-sans text-white">
      <Header />
      <div className="container mx-auto px-4 py-3 sm:px-3">{children}</div>
      <footer className="w-full py-3 text-center text-sm text-neutral-300">
        <p className="!m-0">&copy; 1996 - {new Date().getFullYear()} Camms Road Veterinary Clinic Cranbourne - ABN: 60 416 373 326</p>
        <p className="!m-0">
          Version <span className="rounded bg-slate-600 px-[0.125rem]">{hash}</span> as of <Timestamp date={date} />
        </p>
      </footer>
    </div>
  );
}
